.footer {
    background-color: hsl(0, 0%, 7%);
    color: #fff;
    padding: 40px 0;
    width: 100%;
}

.footer-container {
    max-width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-logo {
    flex: 1;
    text-align: center;
    margin-left: 40px;
}

.footer-logo img {
    width: 100px;
    margin-bottom: 20px;
    margin-left: 40px;
}

.footer-section {
    flex: 1;
    padding: 10px;
    min-width: 300px;
}

.footer-section h4 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #ffffff;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section ul li a:hover {
    color: #007bff;
}

.footer-bottom {
    border-top: 1px solid #444;
    padding-top: 5px;
    text-align: center;
    font-size: 14px;
    color: #aaa;
}

.footer-bottom ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    gap: 20px;
}

.footer-bottom ul li a {
    color: #aaa;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-bottom ul li a:hover {
    color: #007bff;
}

.social-icons {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.social-icons a {
    color: #fff;
    font-size: 20px;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #007bff;
}

/* Popup Styles */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: #222;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: left;
}

.popup-content h2 {
    margin-bottom: 15px;
    font-size: 24px;
    color: #007bff;
}

.popup-content p,
.popup-content ul {
    margin-bottom: 10px;
    line-height: 1.5;
}

.popup-content ul {
    padding-left: 20px;
    list-style-type: disc;
}

.close-button {
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: center;
}

.close-button:hover {
    background-color: #0056b3;
}


@media (max-width: 768px) {
    .footer-logo {
        text-align: center; /* Center the image */
        margin: auto; /* Center the logo container */
    }

    .footer-logo img {
        margin: 0 auto; 
        display: block; 
    }

    .footer-container p {
        text-align: center;
        margin-top: 10px;
    }
}
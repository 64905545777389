.testimonials-section {
  padding: 80px 20px;
  background-color: rgb(10, 0, 25); /* Updated background color */
  text-align: center;
 
  color: #fff;
}

.section-title {
  font-size: 2.8rem;
  margin-bottom: 50px;
  color: #ff8e53;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  padding: 0 40px;
}

.testimonial-card {
  background-color: rgb(20, 2, 48); /* Updated card color */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.testimonial-text {
  font-size: 1.4rem;
  margin-bottom: 15px;
  font-style: italic;
  color: #ffdfba;
}

.testimonial-author {
  font-size: 1.1rem;
  color: #bbb;
}

@media (max-width: 768px) {
  .testimonials-grid {
    grid-template-columns: 1fr;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('https://source.unsplash.com/1600x900/?minimalist,white') no-repeat center center/cover;
  background-color: #f7f7f7; /* Fallback light color */
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  color: #333; /* Darker text for contrast */
  text-align: center;
}

.coming-soon-content {
  background: rgba(255, 255, 255, 0.9); /* Light, translucent background */
  padding: 60px 40px;
  border-radius: 15px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* Lighter shadow */
  animation: fadeIn 2s ease-in-out;
}

.coming-soon-title {
  font-size: 48px;
  margin-bottom: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #2c3e50; /* Darker text color for better readability */
  animation: slideIn 2s ease-out;
}

.coming-soon-description {
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 1.6;
  color: #555; /* Medium gray text for secondary content */
  animation: fadeIn 2.5s ease-in-out;
}

.coming-soon-whatsapp-btn {
  background-color: #25D366;
  color: white;
  padding: 15px 40px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: transform 0.4s ease, background-color 0.4s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Lighter shadow */
  margin-top: 10px;
}

.coming-soon-whatsapp-btn:hover {
  background-color: #20b858;
  transform: translateY(-5px);
}

.coming-soon-continue-link {
  display: block;
  color: #3498db;
  font-size: 16px;
  margin-top: 30px;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.coming-soon-continue-link:hover {
  color: #2980b9;
  text-decoration: underline;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideIn {
  0% { transform: translateY(-50px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

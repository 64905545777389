.assignment-page {
  background-image: url('./images/file.png'); /* Your background image */
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  padding-top: 150px;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
}

.page-heading {
  color: #fcfcfc;
  font-size: 8.5rem; /* Increased font size for the heading */
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

/* Single Card Container */
.assignment-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 40px;
}

/* Single Card for Desktop */
.assignment-card {
  background-color: rgba(35, 30, 60, 1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  padding: 40px; /* Increased padding for more space */
  width: 100%; /* Take up full width on mobile */
  max-width: 800px; /* Increased max width for more space on desktop */
  color: #ffffff;
  margin-bottom: 20px;
}

.assignment-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px; /* Increased margin for more space between rows */
}

.card-title {
  font-weight: bold;
  color: #9bd6e2;
  text-transform: uppercase;
  font-size: 1.5rem; /* Increased font size for titles */
}

.card-value {
  color: #ffffff;
  font-size: 1.5rem; /* Increased font size for values */
  text-align: left;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .assignment-card {
    padding: 30px;
    margin-bottom: 1rem;
  }

  .page-heading {
    font-size: 3rem; /* Adjust heading for mobile */
  }

  .assignment-detail {
    display: block; /* Stack the details vertically */
    text-align: left;
  }

  .card-title, .card-value {
    display: block;
    margin-bottom: 12px; /* More space for vertical stacking */
  }
}

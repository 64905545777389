/* Section styling */
.collaborators-section {
  background: linear-gradient(to right, #0f0c29, #302b63, #24243e);
  padding: 40px 20px;
  text-align: center;
  margin-top: 35px;
}

/* Section title styling */
.collaborators-section h2 {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 30px;
  font-family: 'Arial', sans-serif;
}

.collaborators-section h2 span {
  color: #e9ff25;
}
 
.collaborators-section h3 {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 30px;
  font-family: 'Arial', sans-serif;
}

.collaborators-section h3 span {
  color: #e9ff25;
}

/* Logos container */
.collaborators-logos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive layout */
  gap: 20px;
  justify-items: center;
  align-items: center;
}

/* White tile for each collaborator */
.collaborator-tile {
  background-color: rgba(255, 255, 255, 0.175); /* White tile */
  width: 100%;
  max-width: 300px;
  height: 180px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  border-radius: 18px; /* Rounded corners for a modern look */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.collaborator-tile:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

/* Logos within the tile */
.collaborator-tile img {
  max-width: 80%; /* Ensure logos are properly sized */
  max-height: 100%;
  object-fit: contain; /* Maintain aspect ratio of the logos */
}

/* Mobile view: 3x3 grid layout for smaller screens */
@media (max-width: 768px) {
  .collaborators-logos {
    grid-template-columns: repeat(3, 1fr); /* 3 columns layout for mobile screens */
  }

  .collaborator-tile {
    width: 100%; /* Ensure each tile takes full width within the grid */
    max-width: 100%; /* No max-width constraint to prevent overflow */
  }
}

/* Medium screens (up to 5 columns) */
@media (min-width: 768px) and (max-width: 1199px) {
  .collaborators-section h2 {
    font-size: 24px;
  }

  .collaborators-logos {
    grid-template-columns: repeat(5, 1fr); /* 5 columns for medium screens */
  }

  .collaborator-tile {
    width: 260px;  /* Slightly reduce tile size for medium screens */
    height: 160px;
  }

  .collaborator-tile img {
    max-width: 70%;  /* Adjust logo size */
  }
}

/* Desktop view: 5x2 grid layout for larger screens */
@media (min-width: 1200px) {
  .collaborators-logos {
    grid-template-columns: repeat(5, 1fr); /* 5 columns layout */
    grid-auto-rows: auto; /* Ensure rows wrap to make it 5x2 */
  }

  .collaborator-tile {
    width: 300px;
    height: 180px;
  }

  .collaborator-tile img {
    max-width: 80%;
  }
}

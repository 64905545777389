.course-details-container {
    padding: 80px 20px 20px 20px; 
    min-height: calc(100vh - 160px); 
    background: linear-gradient(135deg, #181e2e, #0f111a, #1c2439);
    color: #ffffff;
  }
  
  .plan-buttons {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; 
    gap: 5px; 
    border-radius: 15%;
    background-color: transparent;
  }
  
  .plan-buttons .MuiButton-root {
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 16px;
    padding: 10px 20px;
    text-transform: none;
    border-radius: 20px;
    background-color: transparent;
    color: #ffffff;
    transition: background-color 0.3s ease, color 0.3s ease;
    flex: 1; 
    max-width: 200px;
  }
  
  .plan-buttons .MuiButton-root:hover {
    background-color: rgba(255, 255, 255, 0.9);
    color: #1e1e1e;
  }
  
  
  .plan-buttons .selected {
    background-color: rgba(255, 255, 255, 0.9); 
    border: 1px solid #fff;
    color: #1e1e1e;
  }
  
  .plan-content {
    margin-top: 20px;
  }
  
  footer {
    position: relative;
    z-index: 10;
    bottom: 0;
    width: 100%;
  }
  @media (max-width: 768px) {
    .plan-buttons {
      justify-content: center; 
    }
  
    .plan-buttons .MuiButton-root {
      font-size: 14px; 
      padding: 8px 15px; 
      max-width: 150px; 
    }
  }
  
  @media (max-width: 480px) {
    .plan-buttons {
      justify-content: center;
    }
  
    .plan-buttons .MuiButton-root {
      font-size: 12px; 
      padding: 6px 10px; 
      max-width: 100%; 
    }
  }
.custom-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-popup-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: #949191; /* Add a background color for visibility */
  padding: 20px; /* Add padding for better content spacing */
  border-radius: 10px; /* Rounded corners */
}

.custom-close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 30px;
  color: #fff; /* Ensure the color contrasts the background */
  cursor: pointer;
}

.custom-popup-image {
  width: 40em;
  height: auto;
  display: block;
  margin: 0 auto; /* Center image horizontally */
}

.custom-apply-now-button {
  position: absolute;
  bottom: 30px;
  right: 30px;
  background: #ffba2c; /* Vibrant color for visibility */
  color: rgb(16, 2, 2);
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1000; /* Ensures it is above other content */
  transition: background-color 0.3s ease;
}

.custom-apply-now-button:hover {
  background: #d14850; /* Darker shade on hover */
}

/* Mobile Screen Adjustments */
@media (max-width: 768px) {
  .custom-popup-content {
    max-width: 95%; /* Slightly wider to accommodate smaller screens */
    max-height: 95%; /* Use more height for smaller screens */
    padding: 15px; /* Reduce padding to save space */
  }

  .custom-popup-image {
    width: 100%; /* Make image responsive */
    height: auto;
  }

  .custom-apply-now-button {
    position: static; /* Remove absolute positioning */
    margin: 20px auto 0; /* Center the button horizontally */
    display: block;
    width: 80%; /* Adjust width for better touch experience */
    text-align: center; /* Center align the text */
  }

  .custom-close-icon {
    top: 5px; /* Adjust positioning for smaller screens */
    right: 5px;
    font-size: 25px; /* Reduce icon size */
  }
}

/* Notification customization for Snackbar and Alert */
.notification-snackbar {
    max-width: 400px;
  }
  
  .notification-alert {
    background-color: #4caf50 !important; /* Custom background color for success */
    color: white; /* Text color */
    font-weight: bold; /* Bold text */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 12px; /* Padding */
  }
  
  /* Optional: Animation for Snackbar */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .notification-snackbar-enter {
    animation: slideIn 0.3s ease-out forwards;
  }
  
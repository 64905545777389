   .chat-popup-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    margin-bottom: 70px;
  }
  
  .chat-toggle-btn {
    background-color: #d31919;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
  }
  /*css for the rest of form excluding headder */
  .chat-popup {
    width: 320px;
    max-width: 100%;
    background-color: #000;
    border: 1px solid #92ccf5;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    height: 620px;
  }
  
  .chat-header {
    background-color: #000;
    color: white;
    padding: 15px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
  }
  
  .chat-header h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .chat-header p {
    margin: 5px 0 0;
    font-size: 18px;
  }
  
  /* Cross Button Styling */
  .chat-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    color: white;
    cursor: pointer;
  }
  
  .chat-form {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #000;
    flex-grow: 1;
  }
  
  .chat-form label {
    color: #fff;
    font-size: 14px;
    margin-bottom: 3px;
    text-align: left;
  }
  
  .chat-form input,
  .chat-form textarea {
    padding: 8px;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    color: #fff;
    background-color: #3a3939;
  }
  
  .chat-form textarea {
    resize: vertical;
    
  }
  
  .chat-submit-btn {
    background-color: #ff4f52;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .chat-submit-btn:hover {
    background-color: #e65c00;
  }
  
  .confirmation-message {
    padding: 20px;
    text-align: center;
    color: #fff;
    background-color: #000;
    border-top: 1px solid #92ccf5;
    flex-grow: 1; /* Ensure message takes up available space */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .confirmation-message p {
    margin-bottom: 15px;
    font-size: 16px;
    color: #fff;
  }
  
  .confirmation-message .chat-submit-btn {
    width: 80px;
    margin: 0 auto;
    background-color: #ff4f52;
  }
  
  .confirmation-message .chat-submit-btn:hover {
    background-color: #e65c00;
  }

  .input-icon {
    position: relative;
  }
  
  .input-icon i {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #ccc;
  }
  
  .input-icon input {
    padding-left: 30px; /* Add padding to avoid overlapping with the icon */
  }
  
  .required {
    color: #ff4f52;
    margin-left: 5px;
    font-weight: bold;
  }
.why-pregrad-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: #fff;
    margin-top: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .why-pregrad-text {
    max-width: 50%;
  }
  
  .why-pregrad-text h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .why-pregrad-text p {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
  }
  
  .slick-slider {
    max-width: 40%;
  }
  
  .slick-slide img {
    width: 100%;
    border-radius: 12px;
  }
  
  .arrow {
    width: 30px;
    height: 30px;
    z-index: 2;
  }
  
  .next {
    right: 10px;
  }
  
  .prev {
    left: 10px;
  }
  
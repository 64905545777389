.carousel-container {
  position: relative;
  margin: 20px auto;
  max-width: 95%;
  height: 280px;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.carousel-heading {
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 40px;
  color: #fff;
  padding-left: 20px;
}

.swiper {
  width: 100%;
  overflow: hidden;
  padding-left: 40px;
  padding-right: 40px;
}

.swiper-wrapper {
  display: flex;
}

.image-box {
  width: 250px;
  height: 140px;
  perspective: 1000px; 
  position: relative; 
  cursor: pointer; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-front, .card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden; 
  border-radius: 8px;
  transition: transform 0.6s; 
}

.card-front {
  background: linear-gradient(to bottom, #bcdfff, #ffe8bc, #faffb8); 
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-back {
  background: linear-gradient(to bottom, #6366f1, #4f46e5); 
  color: white;  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 2px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: rotateY(180deg); 
}

.flipped .card-front {
  transform: rotateY(180deg); 
}

.flipped .card-back {
  transform: rotateY(0); 
}

.carousel-image {
  width: 75%;
  height: 75%;
  object-fit: contain;
}

.swiper-button-prev,
.swiper-button-next {
  display: none; 
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 40;
}

.swiper-pagination {
  display: none; 
}

.swiper-pagination-bullet {
  background: #ccc;
  opacity: 0.7;
}

.swiper-pagination-bullet-active {
  background: #007bff;
  opacity: 1;
}

 

  @media (max-width: 768px) {
    .carousel-heading {
      font-size: 14px; 
      margin-bottom: 40px;
    }

    .image-box {
      width: 60px;
      height: 130px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
      transition: transform 0.3s ease; 
    }

    .image-box:hover {
      transform: scale(1.05); 
    }

    .card-back h3 {
      font-size: 1rem; 
      font-weight: bold;
    }

    .card-back {
      background: linear-gradient(to bottom, #6366f1, #4f46e5); 
      color: white;
      padding: 10px;
      border-radius: 10px;
    }
  
    .card-back p {
      font-size: 0.85rem; 
      line-height: 1.2; 

    }

    .carousel-image {
      width: 65%;
      height: 65%;
    }

  .swiper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 480px) {
  .image-box {
    width: 100px;
    height: 100px;
  }

  .card-back h3 {
    font-size: 0.9rem; 
    font-weight: bold;

  }

  .card-back p {
    font-size: 0.75rem; 
  }

  .carousel-heading {
    font-size: 14px; 
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .card-back {
    background: linear-gradient(to bottom, #4f46e5, #3b82f6); 
    color: white;
    padding: 8px;
    border-radius: 8px;
  }
}

/* Resource page container styles */
.resource-container {
  display: flex;
  justify-content: center;
  min-height: 93vh;
  width: 100%;
  background-image: url('./images/Dark mpdern.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  position: relative;

}

/* Dashboard styles */
.resource-dashboard {
  background-color: #2c2c2c;
  border-right: 2px solid #ffc400;
  height: 70vh;
  transition: transform 0.3s ease;
}

/* Button to toggle dashboard on mobile */
.toggle-dashboard-btn {
  display: none;
  background-color: #ffc400;
  color: #000;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  
  transition: background-color 0.3s ease;
  position: fixed;
  top: 55px; /* Position near the top for easy access */
  left: 10px;
  z-index: 20; /* Ensure it's on top of other elements */
}

.toggle-dashboard-btn:hover {
  background-color: #ff9800;
}

/* Content styles */
.resource-content {
  margin-left: 1rem;
  margin-top: 2rem;
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
  padding: 40px;
  border-radius: 10px;
}

/* Topic card styles */
.resource-topic-card {
  padding: 15px;
  background: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  text-align: left;
  transition: transform 0.5s ease, box-shadow 0.3s ease;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.resource-topic-card h3 {
  font-size: 1.3rem;
  color: #ffcc00;
  text-align: center;
  margin-bottom: 25px;
}

.resource-topic-card ul {
  padding-left: 0;
  list-style: none;
}

.resource-topic-card ul li {
  font-size: 1rem;
  margin-bottom: 10px;
}

.resource-topic-card a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.resource-topic-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  /* Display toggle button on mobile */
  .toggle-dashboard-btn {
    display: block;
  }

  /* Hide dashboard initially on mobile */
  .resource-dashboard {
    transform: translateX(-100%);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 10;
  }

  /* Show dashboard when active */
  .resource-dashboard.active {
    transform: translateX(0);
  }

  /* Stack dashboard and content vertically */
  .resource-container {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }

  /* Adjust content area padding */
  .resource-content {
    margin-left: 0;
    padding: 20px;
  }

  /* Font size adjustments for mobile */
  .resource-topic-card h3 {
    font-size: 1.2rem;
  }

  .resource-topic-card ul li {
    font-size: 0.9rem;
  }

  .resource-dashboard .dashboard-item {
    font-size: 1rem;
  }
}

.test-series-container {
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    /* background-color: #12012d; */
    background: linear-gradient(to right, #0f0c29, #302b63,#24243e);

    padding: 30px 15px;
    border-radius: 1px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    gap: 20px;  
}

.test-series-content {
    margin-top: 10px; 
    max-width: 100%;  
    text-align: center; 
}

.test-series-content h2 {
    font-size: 26px;
    color: #ffffff;
    margin-bottom: 10px;
}

.test-series-content h2 .pass {
    color: #ffffff;
    font-weight: bold;
}

.test-series-content p {
    margin-top: 20px;
    font-size: 15px;
    color: hsl(0, 0%, 100%);
    margin-bottom: 20px;
}

.test-series-content h3 {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 15px;
}

.benefits-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px; 
    padding: 0;
    list-style: none;
    width: 100%; 
}

/* Base styles for benefits list */
.benefits-list li {
    display: flex;
    align-items: center;
    background-color: #3f1483;
    padding: 10px 15px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-position 300ms ease, transform 0.3s ease, box-shadow 0.3s ease;
    background-size: 200% 100%; 
    background-color: linear-gradient(to right, #d7edf8c2 50%, #8218fc 100%); 
    background-position: 0% 0%; 
  }
  
  .benefits-list li:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-position: 100% 0%; 
  }
/*#f4cbf7bb*/
  .benefits-list li .icon {
    font-size: 24px;
    margin-right: 15px; /* Increase space between icon and text */
    color: #ffd000;
}
  
  .benefits-list li p {
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    text-align: left;
    transition: color 0.3s ease;
  }

.explore-btn {
    display: inline-block;
    background-color: #ff6b6b; /* Initial background color */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-position 300ms ease; /* Transition for the hover effect */
    margin-top: 20px;
    background-size: 200% 100%; /* Double the background width */
    background-image: linear-gradient(to right, #ff6b6b 50%, #ff4f4f 50%); /* Sliding gradient from left to right */
    background-position: 0% 0%; /* Initial background position */
}

.explore-btn:hover {
    background-position: 100% 0%; /* Move the background on hover to create the sliding effect */
    color: white;
}

/* Lottie animation positioning */
.test-series-lottie {
    margin-top: 20px;
    text-align: right; /* Shift the Lottie animation more to the right */
    margin-left: 40px;
    padding-left: 20px;
}

/* .test-series-image img {
    width: 100%;
    height: auto;  
    border-radius: 20px;
    object-fit: contain; 
} */

@media (min-width: 768px) {
    .test-series-container {
        flex-direction: row; 
        gap: 20px;
    }

    .test-series-content {
        max-width: 55%; 
        text-align: left; 
    }

    .benefits-list {
        grid-template-columns: repeat(2, 1fr);
    }

    .test-series-lottie {
        max-width: 40%;
    }

    .test-series-lottie iframe {
        width: 100%;  
    }
} 



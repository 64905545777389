.features-section {
  padding: 50px 0;
  background-color: rgb(10, 0, 25); /* Updated background color */
  display: flex;
  justify-content: center;
  align-items: center;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  justify-items: center;
  width: 60%;
}

.feature-card {
  background-color: rgb(20, 2, 48); /* Updated card color */
  border-radius: 15px;
  padding: 40px;
  width: 350px;
  height: 400px;
  text-align: center;
  color: #fff;
  transition: transform 0.3s ease;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card img {
  width: 60px;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 22px;
  margin-bottom: 15px;
}

.feature-card p {
  padding-top: 20px;
  font-size: 16px;
  color: #ccc;
}

@media (max-width: 1024px) {
  .features-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .features-container {
    grid-template-columns: 1fr;
  }

  .feature-card {
    width: 90%;
    height: auto;
  }
}

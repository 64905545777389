/* Carousel Container */
.review-carousel {
    background: #140230;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    perspective: 4000px;
    position: relative;
    height: 60vh;
}

/* Carousel Title */
.carousel-title {
    font-family: 'Poppins', sans-serif; /* Modern, clean font */
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 30px;
    color: #fffae3;
    text-transform: uppercase;
    letter-spacing: 4px;
    animation: title-fade-in 1s ease-in;
    position: relative;
    top: -8px; /* Move the title 20px upward */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

/* Review Card */
.review-card {
    background: #08070a;
    color: #fff;
    border-radius: 15px;
    padding: 35px 20px;
    text-align: center;
    margin: 0 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.8s ease, box-shadow 0.5s ease;
    perspective: 1000px;
    backface-visibility: hidden;
    text-align: center;
    font-family: 'Roboto', sans-serif; /* Clean, professional font */
}

/* Default state for all cards */
.slick-slide {
    opacity: 0.7;
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease;
}

/* Center (active) slide - this is the middle card that pops out */
.slick-slide.slick-current {
    transform: scale(1.1) translateZ(150px); /* Enlarge and lift the center card */
    opacity: 1;
    z-index: 2; /* Bring it to the front */
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  
}

/* Left and right cards (adjacent to the center card) */
.slick-slide.slick-prev {
    transform: rotateY(20deg) translateZ(-100px) translateX(-30px); /* Rotate left card and move back */
    z-index: 1;
    opacity: 0.9;
}

.slick-slide.slick-next {
    transform: rotateY(-20deg) translateZ(-100px) translateX(30px); /* Rotate right card and move back */
    z-index: 1;
    opacity: 0.9;
}

/* Cards further away from the center */
.slick-slide:not(.slick-prev):not(.slick-next):not(.slick-current) {
    transform: scale(0.8) translateZ(-200px); /* Move these cards further back */
    opacity: 0.5;
}

/* Hover effect to enhance card focus */
.review-card:hover {
    transform: scale(1.15) translateZ(150px); /* Enlarge a bit more on hover */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
}

/* Review Text */
.review-text {
    font-size: 14px;
    line-height: 1.4;
    font-style: italic;
    color: #fff;
    margin-bottom: 20px;
    
    
}

/* Review Author */
.review-author h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #d88f2f; /* Accent color */
    text-align: center;
    margin-top: 10px;
}
.slick-center .review-card {
    transform: scale(1.1);
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.8);
  }
.slick-slide {
    padding: 0 10px;
    transition: transform 0.3s ease-in-out;
  }

 
/* Slick Dots */
.slick-dots li button:before {
    font-size: 12px;
    color: #f8f8f8;
    opacity: 0.8;
}

.slick-dots li.slick-active button:before {
    color: #ff6b6b;
    opacity: 1;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    /* Make the container padding smaller for mobile */
    .review-carousel {
        padding: 20px 10px;
        height: auto; /* Adjust the height for better mobile fit */
    }

    .carousel-title {
        font-size: 2rem; /* Reduce the title size */
        letter-spacing: 2px;
        margin-bottom: 20px;
    }

    /* Reduce padding and margins for review cards */
    .review-card {
        padding: 20px 10px;
        margin: 0 5px;
        transform: scale(1) translateZ(0); /* No Z translation on mobile */
    }

    .review-text {
        font-size: 1rem; /* Slightly smaller text */
    }

    /* Adjust center card on mobile */
    .slick-slide.slick-current {
        transform: scale(1) translateZ(0); /* Remove Z translation */
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Less shadow for mobile */
        border: 1px solid #ff6b6b;
    }

    /* Left and right cards on mobile */
    .slick-slide.slick-prev,
    .slick-slide.slick-next {
        transform: rotateY(10deg) translateZ(-50px) translateX(-20px); /* Smaller rotations and translations */
        z-index: 1;
        opacity: 0.8;
    }

    /* Cards further away from the center */
    .slick-slide:not(.slick-prev):not(.slick-next):not(.slick-current) {
        transform: scale(0.9) translateZ(-100px); /* Less scaling on mobile */
        opacity: 0.6;
    }

    /* Adjust slick dots */
    .slick-dots li button:before {
        font-size: 10px; /* Smaller dots */
    }
}

/* Title Fade In Animation */
@keyframes title-fade-in {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.gst {
    font-size: 0.9em;
    color: #666; /* You can change this to any color you prefer */
    margin-left: 5px; /* Adds some space between the price and GST */
  }
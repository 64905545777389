.custom-dashboard {
  margin-top: 60px;
  padding: 0px;
  height: 93vh;
  background-color: #1e1e1e;
  color: #fff;
  margin-left: 0px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.dashboard-title {
  padding-top: 45px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #ffcc00; /* Updated color for title */
}

.dashboard-list {
  padding: 20;
}

.dashboard-list-item {
  color: #fff;
  transition: background-color 0.3s ease;
}

.dashboard-list-item:hover {
  background-color: #333;
}

/* .dashboard-icon {
  color: #fafbfc;
} */
.dashboard-icon {
  color: white;
}

.dashboard-sub-item {
  color: #cccccc;
  padding-left: 60px;
}

.dashboard-sub-item:hover {
  background-color: #444;
}
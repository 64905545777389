/* Layout.css */
.layout-container {
    display: flex;
    margin-left: -15rem;
  }
  
  .layout-container .custom-dashboard {
    width: 250px;
    height: 100vh; /* Fixed height for dashboard */
    overflow-y: scroll; /* Enable scrolling, but hide scrollbar */
    background-color: #1e1e1e;
    color: #fff;
    border-right: 2px solid #ffcc00; /* Yellow line on the right */
    box-sizing: border-box;
    position: fixed; /* Keeps the dashboard fixed */
    top: 0;
    left: 0;
  }
  
  /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
  .layout-container .custom-dashboard::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar in Firefox */
  .layout-container .custom-dashboard {
    scrollbar-width: none; /* Firefox hides scrollbar */
  }
  
  .layout-container .content-wrapper {
    margin-left: 250px; /* Space for the fixed dashboard */
    width: calc(100% - 250px); /* Adjust width based on dashboard */
    padding: 1rem;
    height: 100vh; /* Fixed height for content area */
    overflow-y: scroll; /* Enable scrolling, but hide scrollbar */
    box-sizing: border-box;
  }
  
  /* Hide scrollbar for WebKit browsers in content area */
  .layout-container .content-wrapper::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar in Firefox for content area */
  .layout-container .content-wrapper {
    scrollbar-width: none; /* Firefox hides scrollbar */
  }
  
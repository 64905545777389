.membership-page-background {
  background-image: url('./images/black.svg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.membership-plans-container {
  padding: 2vw 1vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  max-width: 85vw !important;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh; /* Ensures container takes at least the full viewport height */
}

.membership-plan-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px; 
  padding: 2px; 
  background: linear-gradient(90deg, #ff0080, #ff8c00, #40e0d0, #00ff00, #8000ff); 
  background-size: 400% 400%;
  z-index: -1; 
  opacity: 0; 
  transition: opacity 0.3s ease, transform 0.3s ease; 
}

.membership-plan-card:hover::before {
  opacity: 1; 
  animation: animateBorder 4s infinite linear;
}

@keyframes animateBorder {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.membership-plan-card:hover {
  transform: translateY(-5px); 
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2); 
}


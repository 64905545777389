.home-container {
  background-color: #12012d;
  color: #333;
  max-width: 100%;
}


.about-section {
  background: linear-gradient(to right, #0f0c29, #302b63,#24243e);
  color: #f15f62;
  padding: 20px;
  margin: 40px 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;

}

.about-header {
  color: #f15f62;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  top: -20px;
  left: 20px;
  border-radius: 4px;
}

.floating-btn{
  z-index: 10000;
}

.about-content p {
  font-size: 18px;
  line-height: 1.5;
  margin: 10px 0;
  max-width: 100%;
  color: #fff;
}

.read-more-btn {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #eb0e21;
  color: white;
  text-decoration: none;
  transition: background-position 300ms ease;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, #e60d0d 50%, #ff4f4f 50%);
  border-radius: 14px;
  background-position: 0% 0%;
}

.read-more-btn:hover {
  background-position: 100% 0%;
  background-color: #eb0e21;
}

.statistics-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: linear-gradient(to right, #0f0c29, #302b63,#24243e);

  padding: 20px;
  border-radius: 0px;
  margin: 40px auto;
  width: 100%;
  margin-top: 35px;
}

.stat-card {
  display: flex;
  align-items: center;
  background-color: #411482;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: calc(50% - 90px);
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.5s ease;
}

.stat-card:hover {
  transform: translateY(-5px); 
  background-color: #8812f7; 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); 
}

.stat-icon {
  width: 50px; 
  height: 50px; 
  margin-bottom: 10px;
  object-fit: contain; 
}


.stat-card p {

  font-size: 16px;
  color: #ffffff; 

}

.stat-info h3 {
  margin: 0;
  font-size: 24px;
  color: #ffffff; 
}
.whatsapp-community-section {
  text-align: center;
  background: linear-gradient(to right, #0f0c29, #302b63,#24243e);
  padding: 30px 15px;
  margin: 30px auto;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 20em;
}

.whatsapp-community-section h4 {
  margin-top: 20px;
  font-size: 23px;
  font-weight: bold;
  color: #ffd905;
  margin-bottom: 15px;
}

.whatsapp-community-section p {
  margin-top: 30px;
  font-size: 17px;
  color: #ffffff;
  margin-bottom: 15px;
}

.join-whatsapp-btn {
  display: inline-block;
  background-color: #008531;
  color: white;
  padding: 12px 20px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  border-radius: 50px;
}

.join-whatsapp-btn:hover {
  background-color: #1ebc58;
}

.faq-section {
  max-width: 100%;
  margin: 30px 15px;
  padding: 15px;
  text-align: center;
  background: linear-gradient(to right, #0f0c29, #302b63,#24243e);

}

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');

.faq-section h2 {
  font-size: 36px;
  font-weight: bold;
  color:  #f15f62  ; 
  margin-bottom: 10px;
  font-family: 'Oswald', sans-serif;
}


.faq-section p {
  font-size: 14px;
  color: #fff;
  margin-bottom: 20px;
}

.faq-item {
  margin-bottom: 15px;
  cursor: pointer;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background: linear-gradient(90deg, rgba(184, 242, 250, 0.35) 0%, rgba(255, 255, 255, 0) 100%);

}

.faq-answer {
  padding: 12px;
  border-left: 5px solid red;
  background-color: #f9f9f9;
  border-radius: 0 0 5px 5px;
  border-top: none;
  justify-content: space-between;
  align-items: left;
  font-size: 16px;
  color: #333;
}


@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 18px;
  }

  .hero-text p {
    font-size: 12px;
  }

  .statistics-section {
    flex-direction: column;
    width: 95%;
  }

  .stat-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .faq-section {
    margin: 20px 10px;
  }

  .faq-question {
    font-size: 14px;
    padding: 8px;
    color: #ffffff;
  }

  .faq-answer {
    font-size: 14px;
  }

  .review-card {
    width: 100%;
    height: auto;
  }

  .slick-prev, .slick-next {
    width: 30px;
    height: 30px;
  }

  .slick-dots li button:before {
    font-size: 10px;
  }
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 100px;
  background-color: #12012d;
}

.top-section-left {
  flex: 1;
  text-align: left;
}

.camp-heading {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.camp-heading img {
  width: 50px;
  height: 70px;
  margin-right: 15px;
  padding-top: 20px;
}

.camp-heading-text {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  padding-top: 20px;
}

.top-section-right {
  flex: 1;
  text-align: right;
}

/* Main heading */
.main-heading {
  font-size: 56px;
  font-weight: bold;
  color: #fff;
  line-height: 1.2;
  margin-bottom: 20px;
}

.main-heading .highlight {
  color: #f15f62; 
}

.subtext {
  font-size: 20px;
  color: #ddd;
  margin-bottom: 20px;
}

/* Lottie Animation */
.top-section-right iframe {
  max-width: 100%;
  width: 400px;
  height: 400px;
  margin-top: 20px;
  padding-right: 20px;
}

.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #12012d; 
  padding: 50px;
}

.hero-text-box {
  background: linear-gradient(to right, #0f0c29, #302b63,#24243e);
  padding: 40px;
  border-radius: 15px;
  text-align: center;
  width: 100%;
  max-width: 1800px;
  color: #fff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.hero-text-box h1 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.hero-text-box p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #ddd;
}

.applys-btn {
  display: inline-block;
    background-color: #ff6b6b; 
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    text-decoration: none;
    transition: background-position 300ms ease; 
    margin-top: 20px;
    background-size: 200% 100%; 
    background-image: linear-gradient(to right, #ff6b6b 50%, #ff4f4f 50%); 
    background-position: 0% 0%; 

}

.applys-btn:hover {
  background-position: 100% 0%;
  background-color: #eb0e21;

}

@media (max-width: 768px) {

  .top-section {
    margin-top: 50px;
    flex-direction: column;
    text-align: center;
    padding: 20px; 
  }

  .camp-heading {
    justify-content: center;
  }

  .camp-heading img {
    width: 40px; 
    height: 60px;
  }

  .camp-heading-text {
    font-size: 20px;
  }

  .top-section-right {
    order: 2; 
    margin: 20px 0;
  }

  .top-section-left {
    order: 1; 
    text-align: center;
  }

  .main-heading {
    font-size: 28px; 
    margin-bottom: 10px;
  }

  .subtext {
    font-size: 16px;
  }

  .hero-section {
    padding: 20px; 
  }

  .hero-text-box {
    width: 100%; 
    margin: 0 auto;
    padding: 30px; 
  }

  .hero-text-box h1 {
    font-size: 24px;
  }

  .hero-text-box p {
    font-size: 14px;
  }

  .applys-btn {
    font-size: 16px;
    padding: 10px 20px;
  }

  
  .top-section-right iframe {
    width: 300px;  
    height: 300px;
  }
}

.floating-btn {
  position: fixed;
  bottom: 30px; /* Distance from the bottom of the viewport */
  right: 30px; /* Distance from the right of the viewport */
  background-color: #ff4f52;
  color: #fff;
  border: none;
  border-radius: 50px;
  width: 60px; /* Fixed width for the circle */
  height: 60px;
  padding: 12px 24px; /* Adequate padding for text and icon */
  display: flex;
  align-items: center; /* Centers icon and text vertically */
  justify-content: center;
  font-size: 18px; /* Font size for readability */
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for depth */
  overflow: hidden; /* Ensures content doesn't spill out */

}

.floating-btn img {
  width: 30px; /* Adjusts icon size */
  height: 30px;
  object-fit: contain; /* Ensures the image fits proportionally */
  display: block; /* Ensures the image is treated as a block-level element */
  margin-left: 0; /* Resets margin when expanded */
  }

.floating-btn:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
}

.floating-btn:not(.expanded) img {
  margin-left: 60px; /* Adds margin when button is circular */
}

.floating-btn span {
  opacity: 0; /* Hidden when circular */
  white-space: nowrap; /* Prevents wrapping */
  transition: opacity 0.3s ease;
}

.floating-btn.expanded {
  width: auto; /* Auto width for the expanded button */
  height: auto; /* Adjust height to content */
  padding: 12px 24px; /* Adequate padding for expanded state */
  border-radius: 50px; /* Rounded rectangle for expanded button */
  gap: 8px; /* Adds spacing between icon and text */

}

.floating-btn.expanded span {
  opacity: 1; /* Fully visible when expanded */
}

.carousel-container {
 
  max-width: 90%;
  overflow: hidden;
  border-radius: 15px; 
  background: linear-gradient(to right, #0f0c29, #302b63, #24243e); 
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); 
  height: 25vh;
}

.carousel-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
}

.carousel-image {
  width: 100%;
  height: 100%; 
  object-fit: cover; 
  border-radius: 15px; 
}

.carousel-caption {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6); 
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 1rem;
  z-index: 1;
}

@media (max-width: 768px) {
  .carousel-container {
    height: 40vh; 
  }

  .carousel-caption {
    font-size: 0.8rem; 
    padding: 6px 10px;
  }
}


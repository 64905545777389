.referral-section {
  padding: 80px 20px;
  background-color: rgb(10, 0, 25); /* Updated background color */
  text-align: center;
  color: #fff;
}

.section-title {
  font-size: 2.8rem;
  color: #ff8e53;
  margin-bottom: 40px;
}

.referral-content {
  max-width: 800px;
  margin: 0 auto;
}

.referral-text {
  font-size: 1.5rem;
  color: #bbb;
  margin-bottom: 30px;
}

.referral-button {
  padding: 15px 40px;
  background-color: #ff5a5f;
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.referral-button:hover {
  background-color: #e14a4f;
}

.custom-navbar {
  background-color: #000000; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Increased shadow for more emphasis */
  padding: 10px 20px; /* Added padding for better spacing */
}

.navbar-logo {
  font-size: 1.8rem; 
  font-weight: bold;
  color: #ffffff; 
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-logo:hover {
  color: #ffffff; /* Highlight the logo on hover */
  text-decoration: none;
}

.nav-link {
  color: #ffffff;
  margin-right: 30px; /* Increased spacing */
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #ffffff; /* Changed hover color for better contrast */
  text-decoration: none;
}

.navbar-logo img {
  margin-right: 10px;
}

.navbar-book-webinar-btn {
  margin-left: 10px !important; /* Align to the right */
  background-color: #ff9900 !important; /* Ensure background color is applied */
  color: #fff !important; /* Ensure text color is applied */
  padding: 6px 10px !important;
  border-radius: 20px;
  text-decoration: none;
  font-size: 10px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.navbar-book-webinar-btn:hover {
  background-color: #e68900 !important; 
  color: #fff !important; 
}

.nav-link {
  color: #ffffff;
  margin-right: 30px; 
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #388b69; 
  text-decoration: none;
}

.signup-link {
  font-weight: bold;
  color: #ffffff;
  padding: 8px 3px;
  border-radius: 15px;
}

.signup-link:hover {
  color: #df89f9;
  background-color: #171111;
  text-decoration: none;
}

.navbar .dropdown-menu {
  background-color: #000000;
  border: none;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  min-width: 100px; 
  padding: 5px 10px; 
}

.navbar .dropdown-item {
  color: #ffffff;
  margin-right: 10px; 
  font-size: 14px; 
  padding: 5px 1px;
  line-height: 1.5;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar .dropdown-item:hover {
  background-color: #000000; 
  color: #ff9800; 
}

.navbar .fa-user-circle {
  color: #ffffff;
  cursor: pointer;
  font-size: 1.5rem; 
}

#profile-dropdown {
  right: 0;
  left: auto;
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    text-align: left;
    justify-content: flex-end;
  padding-right: 60px;
  }

  .navbar .dropdown-menu {
    width: 100%;
  }
}

.navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-toggler-icon {
  color: #ffffff;
}

.navbar .nav-item .nav-link.active {
  color: #ffc107;
  font-weight: bold;
  margin-right: 30px;
}
.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  margin-right: 10px;
}
